import { Controller } from 'stimulus';

export default class extends Controller {
  // This is a newer implemenation of select2, unlike the files in javascript/select2.
  // This implemenation is more flexible, and shims some event problems with stimulus - select2.
  initialize() {
    const $element = window.$(this.element);

    $element.select2({
      tags: $element.data('dynamic'), // add new options if 'true'
      placeholder: $element.data('placeholder'),
      allowClear: true,
      // width: 'resolve',
      // dropdownAutoWidth: true,
      matcher: function (params, data) {
            if ($.trim(params.term) === '') {
                return data;
            }

            const keywords = (params.term).split(" ");

            for (var i = 0; i < keywords.length; i++) {
                if (((data.text).toUpperCase()).indexOf((keywords[i]).toUpperCase()) == -1) 
                return null;
            }
            return data;
        }
    });

    $element.on('select2:select select2:unselect', (_event) => {
      // Stimulus controllers do not listen for select2 events, so raise a parallel event.
      this.element.dispatchEvent(new Event('change'));
    });
  }
}
